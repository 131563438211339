<template>
  <div class="users">
    <a-spin :spinning="spinning">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" />
      <div></div>
    </a-spin>
  </div>
</template>

<script setup>
import { ref, reactive, toRaw, onMounted } from "vue";
import { publisherAdminApi } from "~/server/index";
import breadcrumbs from "../../breadcrumbs.vue";
import { message } from "ant-design-vue";

const tableRef = ref(null);
const dataList = ref([]);
const spinning = ref(false);
const listTotal = ref(0);

const params = ref({
  emailKeyword: "",
  nameKeyword: "",
  page: 1,
  size: 10,
  status: 0, // 0, 1, 2, 3
});

const breadcrumbsData = {
  title: "Users",
  des: "admin account list",
  links: [
    {
      name: "Home",
      path: "/operator/users",
    },
    {
      name: "Users",
      path: "/operator/users",
    },
  ],
};

const getList = () => {
  spinning.value = true;
  let newParams = { ...params.value };
  publisherAdminApi.getUserList(newParams).then((res) => {
    const { data, success, error } = res;
    if (success) {
      data.data.map((item) => {
        item.isActive = item.status == 0;
      });
      dataList.value = data.data;
      listTotal.value = data.total;
    } else {
      message.error(error);
    }
  });
  spinning.value = false;
};

onMounted(() => {
  getList();
});

</script>

<style lang="less" scoped>
.users {
  padding: 16px;
  padding-top: unset;
}

</style>